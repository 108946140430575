import React from 'react';
import classes from './addFlagForm.css';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';
import useFormValidation from 'src/util/useFormValidation';
import validationRules from './validationRules';
import appActions from 'src/store/actions/app';

import { Button, FormField } from 'fronds/Controllers';

function AddFlagForm(props) {
    const { onSubmit } = props;

    const dispatch = useDispatch();

    const description = 'A general description of what the Flag is for';

    const [newFlag, setNewFlag, formState] = useFormValidation(
        {
            description: ''
        },
        validationRules
    );

    function onFormChange(ev, value) {
        const newForm = { ...newFlag };
        newForm[ev.target.name] = ev.target[value];
        setNewFlag(newForm);
    }

    function submit() {
        onSubmit(newFlag);
    }

    return (
        <form className={classes.root}>
            <FormField label="Description:" description={description}>
                <input
                    name="description"
                    onChange={val => onFormChange(val, 'value')}
                />
            </FormField>
            <div className={classes.controls}>
                {formState.messages.length > 0 ? (
                    <div className={classes.errorMessage}>
                        <p> {formState.messages.join(', ')}</p>
                    </div>
                ) : null}
                <div className={classes.formButtons}>
                    <Button
                        onClick={() =>
                            dispatch(appActions.setConfirmationDialogue(null))
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={!formState.isValid}
                        name="submit"
                        onClick={() => submit()}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
}

AddFlagForm.propTypes = {
    onSubmit: func
};

export default AddFlagForm;
