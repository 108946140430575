const validationRules = new Map();

validationRules.set('description', validateDescription);

function validateDescription({ description }) {
    if (description.length > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Description required' };
    }
}

export default validationRules;
