import urlParamOptions from 'src/constants/urlParamOptions';
import store from 'src/store';

export default class TableRows {
    constructor(rows, headers, pageSize, queryParams) {
        this.rows = rows;
        this.headers = headers;
        this.pageSize = pageSize;
        this.filteredRows = Array.from(rows);
        this.queryParams = queryParams;
    }

    getPage(page = 1) {
        if (this.filteredRows) {
            let start = (page - 1) * this.pageSize;
            let end = page * this.pageSize;

            return this.filteredRows.slice(start, end);
        } else return [];
    }

    getSortedRows() {
        let { sort, asc } = this.queryParams.sort;
        let sortedRows = Array.from(this.filteredRows);

        if (!sort) {
            this.headers.forEach(header => {
                if (header.primaryKey) sort = header.value;
            });
        }

        // If header for sort contains sort by, sort by different header
        const currentHeader = this.headers.find(
            header => header.value === sort
        );
        if (currentHeader && currentHeader.sortBy) sort = currentHeader.sortBy;

        sortedRows.sort((a, b) => {
            a = a[sort];
            b = b[sort];
            if (a == null) return asc ? 1 : -1;
            else if (b == null) return asc ? -1 : 1;
            if (typeof a === 'string' || typeof b === 'string') {
                a = a.toLowerCase();
                b = b.toLowerCase();
            }
            if (asc) {
                return a > b ? 1 : -1;
            } else {
                return b < a ? -1 : 1;
            }
        });
        this.filteredRows = sortedRows;
        return this;
    }

    filterByOverrideStatus() {
        let { filter } = this.queryParams;
        if (filter === undefined) filter = 0;
        filter = parseInt(filter);
        if (filter !== -1) {
            this.filteredRows = this.filteredRows.filter(
                row => row.disposition === filter
            );
        }
        return this;
    }

    filterByFlagStatus() {
        let { filter } = this.queryParams;
        let { invoiceLetterMap } = store.getState().app;
        if (filter === undefined) filter = 0;
        filter = parseInt(filter);
        if (filter === 0) {
            this.filteredRows = this.filteredRows.filter(
                row =>
                    row[invoiceLetterMap.HasFlag] &&
                    !row[invoiceLetterMap.HasDismissedFlag]
            );
        } else if (filter === 1) {
            this.filteredRows = this.filteredRows.filter(
                row =>
                    row[invoiceLetterMap.HasFlag] &&
                    row[invoiceLetterMap.HasDismissedFlag]
            );
        }
        return this;
    }

    filterByLockedStatus() {
        let { filter } = this.queryParams;
        if (!filter) {
            return this;
        } else {
            this.filteredRows = this.filteredRows.filter(
                row => row.isLocked.toString() === filter.toString()
            );
        }
        return this;
    }

    getSearchCriterion() {
        const { query } = this.queryParams;
        // Get keys from headers we are using

        return this.headers.reduce((accum, headerKey) => {
            const searchKey = Object.keys(query).find(key => {
                return key === `${headerKey.value}${urlParamOptions.search}`;
            });

            const optionKey = Object.keys(query).find(
                key => key === `${headerKey.value}${urlParamOptions.option}`
            );

            const key = searchKey ? searchKey : null;
            const searchTerm = key in query ? query[key] : null;
            const selectedSearchOption =
                optionKey in query
                    ? query[optionKey]
                    : headerKey.selectedSearchOption;
            if (searchTerm != null) {
                accum.push({
                    ...headerKey,
                    searchTerm,
                    selectedSearchOption: decodeURI(selectedSearchOption)
                });
            }
            return accum;
        }, []);
    }

    filterBySearch() {
        this.filteredRows = Array.from(this.filteredRows);
        // If there are any column searches, filter through them
        const searchCriteria = this.getSearchCriterion(this.queryParams);
        if (
            searchCriteria &&
            searchCriteria.find(
                searchCriterion => searchCriterion.searchTerm !== null
            )
        ) {
            // Here we let searchedRows filter itself through every viable column specific search
            searchCriteria.forEach(searchCriterion => {
                const format = searchCriterion.format || null;
                const number = parseFloat(
                    cleanInput(searchCriterion.searchTerm)
                );
                // If it isn't a number uses searchString instead
                if (format && !isNaN(number)) {
                    searchCriterion.searchTerm = cleanInput(
                        searchCriterion.searchTerm
                    );
                    this.searchNumber(searchCriterion);
                } else {
                    this.searchString(searchCriterion, format);
                }
            });
        }

        function cleanInput(input) {
            // Remove leading symbols (%, $)
            if (typeof input === 'string') {
                input = input.replace(/[^0-9.-]/, '');
            }

            return input;
        }
        return this;
    }

    searchString(searchCriterion, format) {
        this.filteredRows = this.filteredRows.filter(row => {
            let stringToSearch = row[searchCriterion.value] || '';
            if (format) {
                stringToSearch = format(stringToSearch);
            }

            stringToSearch = stringToSearch.toString().toLowerCase();

            return (
                stringToSearch &&
                stringToSearch.includes(
                    searchCriterion.searchTerm.toString().toLowerCase()
                )
            );
        });
    }

    numberEquals(searchCriterion) {
        this.filteredRows = this.filteredRows.filter(row => {
            const compare = parseFloat(row[searchCriterion.value])
                .toString()
                .slice(0, searchCriterion.searchTerm.length);
            return compare.toString() === searchCriterion.searchTerm.toString();
        });
    }

    numberLessThan(searchCriterion) {
        this.filteredRows = this.filteredRows.filter(row => {
            const compare = row[searchCriterion.value];
            return compare < searchCriterion.searchTerm;
        });
    }

    numberGreaterThan(searchCriterion) {
        this.filteredRows = this.filteredRows.filter(row => {
            const compare = row[searchCriterion.value];
            return compare > searchCriterion.searchTerm;
        });
    }

    searchStringNumber(searchCriterion) {
        this.filteredRows = this.filteredRows.filter(row => {
            return (
                row[searchCriterion.value] &&
                row[searchCriterion.value]
                    .toString()
                    .toLowerCase()
                    .includes(
                        searchCriterion.searchTerm.toString().toLowerCase()
                    )
            );
        });
    }

    searchNumber(searchCriterion) {
        switch (searchCriterion.selectedSearchOption) {
            case 'in':
                this.searchStringNumber(searchCriterion);
                break;
            case '=':
                this.numberEquals(searchCriterion);
                break;
            case '<':
                this.numberLessThan(searchCriterion);
                break;
            case '>':
                this.numberGreaterThan(searchCriterion);
        }
    }
}
