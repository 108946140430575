import React, { useRef } from 'react';
import classes from './flagRules.css';
import headers from './headers';
import { instanceOf } from 'prop-types';
import { useDispatch } from 'react-redux';
import TableRows from 'src/util/tableRows';
import QueryParams from 'src/util/urlParams/queryParams';
import PageHeader from 'src/components/PageHeader';
import TableWrapper from 'src/components/TableWrapper';
import { TableController } from 'fronds/Components';
import { Button } from 'fronds/Controllers';
import Splash from 'src/components/Splash';
import appActions from 'src/store/actions/app';
import { resultsPerPageDropdownOptions } from 'src/constants/resultsPerPageOptions';
import NoRules from 'src/icons/norules2.svg';
import AddFlagForm from './addFlagForm';
import { newFlagForm } from 'src/components/Dialogue/messages';
import { createFlag } from 'src/store/actions/ruleAsyncActions';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import PaginationWrapper from 'src/components/PaginationWrapper';

function FlagRulesPage(props) {
    const { tableRows, queryParams } = props;
    const dispatch = useDispatch();

    const initialColumnSearch = queryParams.getColumnSearch(headers);

    function handleColumnSearch(search, header) {
        queryParams
            .setColumnSearchParam(header, search, headers)
            .setColumnComparatorParams(header, headers)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleSort(sort, asc) {
        queryParams
            .setSortParams(sort, asc)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleResultsPerPageChange(resultsPerPage) {
        queryParams
            .setResultsPerPageParam(resultsPerPage.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    const buttonRef = useRef(null);
    const fallback = (
        <Splash
            message={'No Flags have been created yet'}
            callToAction={'Create a New Flag'}
            action={() => buttonRef.current.click()}
            image={<NoRules />}
        />
    );

    function submitNewFlag(newFlag) {
        dispatch(createFlag(newFlag));
        dispatch(appActions.setConfirmationDialogue(null));
    }

    const formButton = (
        <Button
            buttonRef={buttonRef}
            onClick={() =>
                dispatch(
                    appActions.setConfirmationDialogue(
                        newFlagForm(<AddFlagForm onSubmit={submitNewFlag} />)
                    )
                )
            }
        >
            Create New Flag
        </Button>
    );

    const headerProps = {
        initialSort: queryParams.sort,
        handleSort,
        initialColumnSearch,
        handleColumnSearch
    };

    return (
        <div className={classes.root}>
            <PageHeader text={'Flag Rules'} />
            <TableController
                inheritedClass={classes.tableController}
                left={formButton}
                right={
                    <>
                        <DropdownSelectWrapper
                            label={'Page Size'}
                            inheritedClass={'mini'}
                            listItems={resultsPerPageDropdownOptions}
                            selectedValues={
                                resultsPerPageDropdownOptions.find(
                                    opt =>
                                        opt.value === queryParams.resultsPerPage
                                ) || resultsPerPageDropdownOptions[0]
                            }
                            onSelectionChange={handleResultsPerPageChange}
                        />
                        <PaginationWrapper
                            totalRows={
                                tableRows.filteredRows
                                    ? tableRows.filteredRows.length
                                    : 0
                            }
                            handlePagination={page => {
                                queryParams
                                    .setPaginationParams(parseInt(page))
                                    .setURLParams();
                            }}
                            currentPage={queryParams.pageNumber}
                            pageSize={queryParams.resultsPerPage}
                        />
                    </>
                }
            />
            <TableWrapper
                rows={tableRows.getPage(queryParams.pageNumber)}
                headers={headers}
                headerProps={headerProps}
                fallback={fallback}
                primaryKey={'id'}
            />
        </div>
    );
}

FlagRulesPage.propTypes = {
    tableRows: instanceOf(TableRows),
    queryParams: instanceOf(QueryParams)
};

export default FlagRulesPage;
