import React from 'react';
import RuleToggle from 'src/components/RuleToggle';
import { FlagIcons } from 'src/components/TableRowActionIcons';
import { object, string } from 'prop-types';
import treeTypes from 'src/constants/treeTypes';

export default [
    {
        label: '',
        sortable: false,
        searchable: false,
        value: 'toggle',
        static: true,
        component: RowRuleToggle
    },
    {
        value: 'id',
        label: 'Flag Id',
        primaryKey: true
    },
    {
        value: 'description',
        label: 'Description'
    },
    {
        label: '',
        searchable: false,
        class: 'iconTray',
        value: 'icons',
        sortable: false,
        component: FlagIcons
    }
];

function RowRuleToggle(props) {
    const {
        row: { id, enabled }
    } = props;
    return <RuleToggle enabled={enabled} id={id} treeType={treeTypes.flag} />;
}

RowRuleToggle.propTypes = {
    row: object,
    effectiveDateStart: string,
    effectiveDateEnd: string
};
